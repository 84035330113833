// import '@popperjs/core';
// import 'bootstrap';

// core version + navigation, pagination modules:
import Swiper, {
    Navigation,
    Pagination,
    Thumbs
} from 'swiper';



// init Swiper:
const swiper_fwidth = new Swiper('.swiper-sl-fwidth', {
    // configure Swiper to use modules
    modules: [Navigation],

    // loop: true,
    slidesPerView: 4.2,
    centeredSlides: true,
    spaceBetween: 15,
    initialSlide: 3,

    // If we need pagination
    // pagination: {
    //     el: '.swiper-pagination',
    // },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1.2,
            spaceBetween: 15
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 3.2,
        },
        // when window width is >= 640px
        1200: {
            slidesPerView: 4.2,
        }
    }
});


const swiper_simple = new Swiper('.swiper-sl-simple', {
    // configure Swiper to use modules
    modules: [Navigation],

    loop: true,
    slidesPerView: 3,
    spaceBetween: 15,

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 15
        },
        768: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 3,
        },
        // 1024: {
        //     slidesPerView: 3,
        // },
        // 1600: {
        //     slidesPerView: 2,

    }
});


const swiper_ft_thumb = new Swiper('.swiper-sl-ft-thumb', {
    // configure Swiper to use modules
    modules: [Navigation, Thumbs],

    loop: false,
    slidesPerView: 4,
    spaceBetween: 10,

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            // spaceBetween: 15
        },
        768: {
            slidesPerView: 2,
            // spaceBetween: 15
        },
        1024: {
            slidesPerView: 3,

            // spaceBetween: 15
        },
        1520: {
            slidesPerView: 4,
            // spaceBetween: 15
        },

    }
});


const swiper_ft = new Swiper('.swiper-sl-ft', {
    // configure Swiper to use modules
    modules: [Navigation, Thumbs],

    // loop: true,
    slidesPerView: 1,
    // spaceBetween: 15,

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            // spaceBetween: 15
        }
    },

    thumbs: {
        swiper: swiper_ft_thumb,
    }
});
